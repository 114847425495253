"use client"

import Link from "next/link"
import { usePathname } from "next/navigation"
import { type NavItem } from "@/types"

import { cn } from "@/lib/utils"
import { useTranslation } from "@/providers/translation-provider"

interface MenuItemProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  item: NavItem
  icon?: React.ReactNode
  isBrand?: boolean
  isAllbrand?: boolean
  isAdmin?: boolean
}

export function MenuItem({
  item,
  icon,
  className,
  isBrand = false,
  isAllbrand = false,
  isAdmin = false,
  ...rest
}: MenuItemProps) {
  const pathname = usePathname()
  const { t } = useTranslation()

  const segments = pathname.split("/")

  const getHref = (): string => {
    if (item.label === `พรีวิวหน้า${t.class}`) {
      return `/preview/${segments[2]}/${segments[3]}`
    }
    if (isAdmin) {
      return `/${item.href}`
    }
    if (isAllbrand) {
      return `/manage/${item.href}`
    }
    if (isBrand) {
      return `/manage/${segments[2]}/${item.href}`
    }
    return `/manage/${segments[2]}/${segments[3]}/${item.href}`
  }

  const isActive = (): boolean => {
    if (isBrand && item.label === `${t.class}ทั้งหมด` && !segments[3]) {
      return true
    }
    if (isAllbrand && item.label === "แบรนด์ทั้งหมด" && (segments.length === 2 || segments[2] === "create-brand")) {
      return true
    }
    if (pathname.includes(item.href) && item.label !== `${t.class}ทั้งหมด` && item.label !== "แบรนด์ทั้งหมด") {
      return true
    }
    if (isAdmin && item.label === "แบรนด์ทั้งหมด" && pathname.includes("admin")) {
      return true
    }
    return false
  }

  const newClassName = cn(
    "group flex h-10 w-full items-center gap-3 rounded-lg bg-transparent px-3 py-2 text-md font-semibold text-quaternary hover:bg-primary-hover",
    { "rounded-lg bg-brand-secondary text-brand-secondary": isActive() },
    className
  )

  return (
    <Link {...rest} aria-label={item.label} href={getHref()} className={newClassName}>
      {icon}
      <span>{item.label}</span>
    </Link>
  )
}
