"use client"

import { useState } from "react"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { ChevronDown, ChevronUp } from "lucide-react"

import { type NavConfig } from "@/config/nav"
import { cn } from "@/lib/utils"
import { useTranslation } from "@/providers/translation-provider"

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { Icons } from "@/components/icons"

interface MenuGroupItemProps {
  group: NavConfig
  isBrand?: boolean
  isAllbrand?: boolean
}

export function MenuGroupItem({ group, isBrand = false, isAllbrand = false }: MenuGroupItemProps) {
  const [open, setOpen] = useState(false)
  const pathname = usePathname()
  const segments = pathname.split("/")

  const { t } = useTranslation()

  const isMemberInvitePage = segments.length === 6 && segments[4] === "members"
  const isMemberPage = segments.length === 5 && segments[4] === "members"
  const isSupervisorPage = segments.length === 4 && segments[3] === "supervisors"
  const isSupervisorInvitePage = segments.length === 5 && segments[3] === "supervisors"

  const Icon = group.icon ? Icons[group.icon] : undefined

  return (
    <Collapsible className="w-full" onOpenChange={setOpen} open={open}>
      <CollapsibleTrigger asChild>
        <div className="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-md font-semibold text-quaternary hover:bg-primary-hover">
          <div className="flex items-center gap-3">
            {Icon && <Icon className="size-5" aria-hidden="true" />}
            <span>{group.name}</span>
          </div>
          {open ? (
            <ChevronUp className="size-5 text-fg-quaternary" />
          ) : (
            <ChevronDown className="size-5 text-fg-quaternary" />
          )}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        {group.nav.map((item) => (
          <Link
            key={item.href}
            aria-label={item.label}
            href={
              item.label === `พรีวิวหน้า${t.class}`
                ? `/preview/${segments[2]}/${segments[3]}`
                : isAllbrand
                ? `/manage/${item.href}`
                : isBrand
                ? `/manage/${segments[2]}/${item.href}`
                : `/manage/${segments[2]}/${segments[3]}/${item.href}`
            }
            className={cn(
              "flex h-[33px] cursor-pointer items-center rounded-md py-[6px] pl-12 pr-3 text-sm font-normal text-quaternary hover:bg-primary-hover",
              isBrand && item.label === `${t.class}ทั้งหมด` && !!!segments?.[3]?.length
                ? "text-brand-secondary"
                : isAllbrand &&
                  item.label === "แบรนด์ทั้งหมด" &&
                  (segments.length === 2 || segments[2] === "create-brand")
                ? " text-brand-secondary"
                : (isMemberInvitePage && item.label === "ลิงก์คำเชิญ") ||
                  (isSupervisorPage && item.label === "ผู้ดูแลแบรนด์") ||
                  (isSupervisorInvitePage && item.label === "ลิงก์คำเชิญ") ||
                  (isMemberPage && item.label === `สมาชิก${t.class}`)
                ? " text-brand-secondary"
                : pathname.includes(item.href) &&
                  item.label !== `${t.class}ทั้งหมด` &&
                  item.label !== "แบรนด์ทั้งหมด" &&
                  item.label !== `สมาชิก${t.class}` &&
                  item.label !== "ลิงก์คำเชิญ" &&
                  item.label !== "ผู้ดูแลแบรนด์"
                ? " text-brand-secondary"
                : ""
            )}
          >
            <span>{item.label}</span>
          </Link>
        ))}
      </CollapsibleContent>
    </Collapsible>
  )
}
